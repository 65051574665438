import React from "react"
import { Link } from "gatsby"
import Div100vh from "react-div-100vh"

export default function carnegieMellon() {
  return (
    <Div100vh id="entire">
      <header>
        <nav>
          <ul id="headerMenu">
            <li className="menuItem" id="name">
              <Link to="/">hannah s. kim</Link>
            </li>
            <li className="menuItem" id="about">
              <Link to="/about">about</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="hero">
        <div className="projectText">
          <h1 className="heroSectionProjectTitles">
            Carnegie Mellon's Human-Computer Interaction Institute:
            "Tabhoarding" Research
          </h1>
          <p>
            Contact me at{" "}
            <a
              href="mailto:hello@hannahs.kim"
              className="externalLink"
              id="email"
            >
              hello@hannahs.kim
            </a>{" "}
            with your questions!
          </p>
          <h1 className="heroSectionTitles">Background</h1>
          <p>
            Carnegie Mellon’s Human-Computer Interaction Institute (HCII) hosts
            a National Science Foundation funded Research Experience for
            Undergraduates (REU), which gives undergraduates the opportunity to
            research in their lab of acceptance. As a REU student, I
            collaborated closely with 2 researchers, Joseph Chee Chang and John
            Hwong, to explore the problem space around tabbed browsing in
            Professor Niki Kittur’s lab.
          </p>
          <h1 className="heroSectionTitles">Objective &#38; Work</h1>
          <p>
            We wanted to gather deep qualitative insights on people’s challenges
            with tabbed browsing and their tab management strategies. Common
            browsing behaviors lead people to have too many tabs open at once.
            But when people have too many tabs open, they experience emotional
            and productivity costs.
          </p>
          <p>
            In collaboration with the aforementioned researchers, I designed a
            discussion guide for semi-structured interviews. Over 2 weeks, we
            conducted 4 rounds of interviews. We wanted to see if there would be
            changes in our participants’ number of opened tabs or tab management
            strategies as they progressed through open-ended research work.
            Following the interviews, we looked for emerging patterns for the
            reasons or types of situations that caused an excessive number of
            tabs and started to generate codes in order to do thematic analysis.
          </p>
          <h1 className="heroSectionTitles">Result &#38; Reflection</h1>
          <p>
            This work was one of three studies that shaped a CHI 2021 paper on
            which I am listed as a co-author (see{" "}
            <a
              href="https://joe.cat/images/papers/tabs.pdf"
              rel="noreferrer noopener"
              target="_blank"
              className="externalLink"
            >
              HERE
            </a>
            ). It received an 'Honorable Mention'. Reflecting upon the work, I
            would have narrowed the scope of interviews to get higher quality,
            though less, data. For example, I gathered data on some early design
            concepts that turned out to be of little use because of where we
            were in our design process. By narrowing the scope of our
            interviews, I could have further probed on participants’ answers or
            simply save everyone somoe time with shorter interviews.
          </p>
          <h1 className="heroSectionProjectTitles">Other Work</h1>
          <ul>
            <li className="sectionListItem" id="carnegieLastListItem">
              <Link to="/workdaypayondemand">■ Workday: Pay On-Demand</Link>
            </li>
          </ul>
        </div>
         
      </div>
      <footer>
        <ul id="footerLinks">
          <li>
            <a
              href="mailto: hello@hannahs.kim"
              className="footerItem"
              id="email"
            >
              hello@hannahs.kim→
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/hannahs-kim"
              className="footerItem"
              id="linkedIn"
            >
              My LinkedIn→
            </a>
          </li>
        </ul>
      </footer>
    </Div100vh>
  )
}
